/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var toggles = document.querySelectorAll(".mobile-nav-toggle");

        function toggleHandler(toggle) {
          toggle.addEventListener( "click", function(e) {
            e.preventDefault();
            if (this.classList.contains("is-active") === true) {
              this.classList.remove("is-active");
              $("body").removeClass("menu-active");
            } else {
              this.classList.add("is-active");
              $("body").addClass("menu-active");
            }
            $('.navigation-wrapper').toggle();
          });
        }

        for (var i = toggles.length - 1; i >= 0; i--) {
          var toggle = toggles[i];
          toggleHandler(toggle);
        }

        $(window).resize(function() {
          // This will execute whenever the window is resized
          $width = window.innerWidth;
          $height = window.innerHeight;

          $video_aspect = 1.89;
          $viewport_aspect = $width/$height;

          $display_aspect_adjust = $video_aspect-$viewport_aspect;

          $video_width = $width-($width*$display_aspect_adjust);
          $video_height = $height-($height*$display_aspect_adjust);

          if($video_aspect === $viewport_aspect) {
            $('#embedWrapper iframe').width($width);
            $('#embedWrapper iframe').height($height);
          } else if($viewport_aspect < $video_aspect) {
            $('#embedWrapper iframe').width($height*$video_aspect);
            $('#embedWrapper iframe').height($height);
          } else if($viewport_aspect > $video_aspect) {
            $('#embedWrapper iframe').width($width);
            $('#embedWrapper iframe').height($width/$video_aspect);
          }
        }).resize();

        var $grid = $('.gallery');

        $grid.imagesLoaded().progress( function() {
          $('.gallery').magnificPopup({
            delegate: 'a.image',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-fade',
            disableOn: 400,
            gallery: {
              enabled: true,
              navigateByImgClick: true,
              preload: [0,1], // Will preload 0 - before current, and 1 after the current image
              arrowMarkup: '<span class="mfp-arrow mfp-arrow-%dir%"><svg class="icon-arrow"><use xlink:href="#icon-arrow"></use></span>',
            },
            image: {
              tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            },
            removalDelay: 300,
            closeMarkup: '<span title="%title%" class="mfp-close"><svg class="icon-close"><use xlink:href="#icon-close"></use></span>',
          });
        });

        // Temp fix for magnific popup close issue
        $(document).on('click', '.mfp-close', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });

        $('a.smoothscroll').click(function(){
            $('html, body').animate({
                scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top
            }, 500);
            return false;
        });

        $('.nav-primary ul a').hover(function() {
          $(this).parent().parent().toggleClass('hover');
        });

        $('.hideme').each( function(i){
          var bottom_of_object = $(this).offset().top + $(this).outerHeight()*0.3;
          var bottom_of_window = $(window).scrollTop() + $(window).height();

          /* If the object is completely visible in the window, fade it it */
          if( bottom_of_window > bottom_of_object ){
              $(this).animate({'opacity':'1'},600);
          }
        });

        /* Every time the window is scrolled ... */
        $(window).scroll( function(){
          /* Check the location of each desired element */
          $('.hideme').each( function(i){
            var bottom_of_object = $(this).offset().top + $(this).outerHeight()*0.3;
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window > bottom_of_object ){
                $(this).animate({'opacity':'1'},600);
            }
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var touch = window.ontouchstart || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

        if (touch) { // remove all :hover stylesheets
            try { // prevent crash on browsers not supporting DOM styleSheets properly
                for (var si in document.styleSheets) {
                    var styleSheet = document.styleSheets[si];
                    if (!styleSheet.rules) {
                      continue;
                    }

                    for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) {
                          continue;
                        }

                        if (styleSheet.rules[ri].selectorText.match(':hover')) {
                            styleSheet.deleteRule(ri);
                        }
                    }
                }
            } catch (ex) {}
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        var iframe = $('#homeVideo')[0];
        var player = $f(iframe);

        player.addEvent('ready', function() {
          player.api('setVolume', 1);
        });

        // $('.volume-toggle').on('click', function() {
        //   if($(this).is('.volume-off')) {
        //     player.api('setVolume', 0.01);
        //     $(this).removeClass('volume-off');
        //   } else {
        //     player.api('setVolume', 0);
        //     $(this).addClass('volume-off');
        //   }
        // });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'blog': {
      init: function() {
        // JavaScript to be fired on the home page
        $('article').hover(function() {
          $(this).find('.article-wrapper').toggleClass('hover');
        });
        $('article').click(function(event) {
          window.location = $(this).find("a").attr("href");

          return false;
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
